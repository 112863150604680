<template>
  <b-overlay
    :show="loading"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <div class="dashboard-company-container">
      <b-col>
        <b-row align-h="between">
          <b-col>
            <b-row class="mb-4">
              <b-col class="title">
                {{ $store.getters.state.user.name }}
              </b-col>
            </b-row>
            <!--  <b-row>
              <b-col>
                Definições gerais
              </b-col>
            </b-row> -->
            <b-row>
              <b-col>
                <b class="mr-2">Email:</b>

                {{ $store.getters.state.user.email }}
                <div
                  v-if="editEmail"
                  class="d-flex flex-row my-2 align-items-center"
                >
                  <b-form-group
                    id="input-group-4"
                    class="m-0 mr-4 "
                    label-for="input-4"
                  >
                    <b-form-input
                      id="input-4"
                      :value="$store.getters.state.user.email"
                      type="email"
                      required
                      placeholder="Email do respondente"
                      @input="
                        (value) => {
                          newEmail = value;
                        }
                      "
                    />
                  </b-form-group>
                  <div class="buttons my-0">
                    <div class="button sm primary">
                      Alterar
                    </div>
                    <div
                      class="button sm secondary"
                      @click="
                        () => {
                          editEmail = false;
                          newEmail = null;
                        }
                      "
                    >
                      Cancelar
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col style="display: flex; flex-direction: row;">
                <b class="mr-2">Empresa: </b>

                <div class="buttons justify-content-start my-0">
                  <div
                    class="button outlined secondary no-border no-bg sm"
                    style="padding: 0;"
                    @click="setState(4)"
                  >
                    {{ name }}
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b class="mr-2">Cargo:</b>

                {{ options[$store.getters.state.user.role] }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b class="mr-2">Permissões:</b>

                {{ permissions[$store.getters.state.user.permission_id] }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b class="mr-2">Perfil SHIFT2Future:</b>

                {{
                  $store.getters.state.user.permission_id === 1
                    ? "Administrador"
                    : $store.getters.state.user.permission_id && !isFacilitator
                      ? "Utilizador"
                      : "Facilitador"
                }}
              </b-col>
            </b-row>
            <b-row class="mt-5 mb-2">
              <b-col>
                <div class="buttons justify-content-start my-0 ">
                  <!--  <div
                    v-if="editEmail === false"
                    class="button primary sm no-border no-bg text-primary"
                    style=" padding: 0; text-decoration: underline;"
                    @click="
                      () => {
                        editEmail = true;
                      }
                    "
                  >
                    <b>Alterar Email</b>
                  </div> -->
                  <div
                    class="button primary sm no-border no-bg text-primary"
                    style=" padding: 0; text-decoration: underline;"
                    @click="$bvModal.show('changePassword')"
                  >
                    <b>Alterar Password</b>
                  </div>
                </div>
                <!--  <div class="button button-danger">
              Apagar conta
            </div> -->
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="
              (!asking &&
                !isFacilitator &&
                parseInt($store.getters.state.user.permission_id) !== 1)
            "
          >
            <b-row>
              <b-col>
                <div class="buttons justify-content-end my-0">
                  <div
                    class="button sm primary"
                    @click="askFacilitatorProfile"
                  >
                    Solicitar perfil de facilitador
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="success || error"
              class="feedback-row"
            >
              <b-col
                cols="12"
                class="p-0"
              >
                <div
                  v-if="success"
                  class="error-success"
                >
                  O seu pedido foi efetuado com sucesso!
                </div>
              </b-col>
              <b-col
                cols="12"
                class="p-0"
              >
                <div
                  v-if="error"
                  class="error-danger"
                >
                  Ocorreu um erro com o seu pedido. Tente novamente mais tarde!
                </div>
              </b-col>
            </b-row>
            <b-row v-if="awaitResponse == false">
              <b-col
                v-if="!isFacilitator"
                cols="12"
              >
                <div
                  v-if="asking"
                  class="my-2 mt-5"
                >
                  O seu perfil está a ser analisado para que possa ser
                  facilitador da plataforma.
                </div>
                <div
                  v-else-if="rejected"
                  class="mt-5"
                >
                  <div class="my-2">
                    O seu pedido foi rejeitado!
                    <div
                      v-if="feedback && feedback.length !== 0"
                      class="mt-2"
                    >
                      <b>Motivo de rejeição: </b>
                      <div v-html="feedback.split('\n').join('<br>')" />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal
          id="changePassword"
          centered
          hide-footer
          header-class="border-bottom-0"
          footer-class="border-top-0"
          size="lg"
        >
          <div class="text-left">
            <div class="title">
              Alterar password
            </div>
            <b-form @submit="resetPassword">
              <b-form-group
                id="input-group-5"
                label="Email *"
                hidden="true"
                label-for="input-5"
                class="open-sans-light fs-14 text-dark"
              >
                <b-form-input
                  id="input-4"
                  v-model="$store.getters.user.email"
                  type="email"
                  required
                  placeholder="Email"
                  hidden="true"
                />
              </b-form-group>
              <b-form-group
                id="input-group-5"
                label="Password *"
                label-for="input-5"
                class="open-sans-light fs-14 text-dark"
              >
                <b-form-input
                  id="input-5"
                  v-model="form.password"
                  type="password"
                  required
                  :state="passwordValid"
                  placeholder="Password"
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  A password deve conter pelo menos 8 caractéres e 2 das
                  seguintes condições:
                  <ul>
                    <li>Caracteres especiais</li>
                    <li>Letras maiúsculas e minúsculas</li>
                    <li>Caracteres numéricos</li>
                  </ul>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row class="mb-10">
                <b-col>
                  Segurança da password:
                  {{
                    strong == 0
                      ? "Nenhuma"
                      : strong == 1
                        ? "Fraca"
                        : strong == 2
                          ? "Média"
                          : "Forte"
                  }}
                </b-col>
              </b-row>
              <b-row class="security-display">
                <b-col
                  class="level"
                  :class="[
                    strong >= 0 && 'visible-level',
                    strong == 0 && 'last',
                  ]"
                >
                  <div
                    :class="
                      strong == 0
                        ? 'danger'
                        : strong == 1
                          ? 'danger'
                          : strong == 2
                            ? 'warning'
                            : 'success'
                    "
                  />
                </b-col>
                <b-col
                  class="level"
                  :class="[
                    strong >= 1 && 'visible-level',
                    strong == 1 && 'last',
                  ]"
                >
                  <div
                    :class="
                      strong >= 1
                        ? strong == 0
                          ? ''
                          : strong == 1
                            ? 'danger'
                            : strong == 2
                              ? 'warning'
                              : 'success'
                        : ''
                    "
                  />
                </b-col>
                <b-col
                  class="level"
                  :class="[
                    strong >= 2 && 'visible-level',
                    strong == 2 && 'last',
                  ]"
                >
                  <div
                    :class="
                      strong >= 2
                        ? strong == 0
                          ? ''
                          : strong == 1
                            ? 'danger'
                            : strong == 2
                              ? 'warning'
                              : 'success'
                        : ''
                    "
                  />
                </b-col>
                <b-col
                  class="level"
                  :class="[
                    strong >= 3 && 'visible-level',
                    strong == 3 && 'last',
                  ]"
                >
                  <div
                    :class="
                      strong >= 3
                        ? strong == 0
                          ? ''
                          : strong == 1
                            ? 'danger'
                            : strong == 2
                              ? 'warning'
                              : 'success'
                        : ''
                    "
                  />
                </b-col>
              </b-row>
              <b-form-group
                id="input-group-6"
                label="Confirmar password *"
                label-for="input-6"
              >
                <b-form-input
                  id="input-6"
                  v-model="form.cpassword"
                  type="password"
                  required
                  placeholder="Confirmar password"
                  :state="equalPasswords"
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  As passwords devem coincidir.
                </b-form-invalid-feedback>
              </b-form-group>
              <div>* Campo de preenchimento obrigatório</div>
              <b-row v-if="equalPasswords && passwordValid">
                <b-col>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="button-primary rubik-regular fs-15"
                    style="margin: 0;"
                  >
                    Alterar password
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-modal>
      </b-col>
    </div>
  </b-overlay>
</template>
<script>
import { get, post, put } from '../../services/api/index';

export default {
  name: 'DashboardUser',
  props: {
    setState: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      name: null,
      passwords: false,
      passwordLength: false,
      form: {
        email:
          this.$store.getters.resetEmail !== null
            ? this.$store.getters.resetEmail
            : '',
        password: '',
        cpassword: '',
      },
      permissions: {
        1: 'Administrador',
        2: 'Utilizador da empresa',
        3: 'Gestor da empresa',
      },
      options: {
        1: 'Gestão de topo / Dirigente superior',
        2: 'Gestão intermédia / Dirigente intermédio',
        3: 'Técnico(a) / Técnico(a) superior',
        4: 'Outro',
      },
      success: false,
      error: false,
      isFacilitator: false,
      asking: false,
      rejected: false,
      awaitResponse: true,
      feedback: '',
      editEmail: false,
      newEmail: null,
    };
  },
  computed: {
    strong() {
      if (!this.passwordValidLength) {
        return 0;
      }
      const passwordValidNumber = this.passwordValidNumber ? 1 : 0;
      const passwordValidCharacter = this.passwordValidCharacter ? 1 : 0;
      const passwordValidUpperCase = this.passwordValidUpperCase ? 1 : 0;

      return (
        passwordValidNumber + passwordValidCharacter + passwordValidUpperCase
      );
    },
    passwordValid() {
      if (this.form.password === '') {
        return null;
      }

      const passwordValidNumber = this.passwordValidNumber ? 1 : 0;
      const passwordValidCharacter = this.passwordValidCharacter ? 1 : 0;
      const passwordValidUpperCase = this.passwordValidUpperCase ? 1 : 0;

      if (!this.passwordValidLength) {
        return false;
      }

      if (
        passwordValidNumber + passwordValidCharacter + passwordValidUpperCase
        >= 2
      ) {
        return true;
      }
      return false;
    },
    passwordValidLength() {
      if (this.form.password === '') {
        return null;
      }
      return this.form.password.length >= 8;
    },
    passwordValidNumber() {
      if (this.form.password === '') {
        return null;
      }
      return /\d/.test(this.form.password);
    },
    passwordValidCharacter() {
      if (this.form.password === '') {
        return null;
      }
      return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        this.form.password,
      );
    },
    passwordValidUpperCase() {
      if (this.form.password === '') {
        return null;
      }
      return (
        /[A-Z]/.test(this.form.password) && /[a-z]/.test(this.form.password)
      );
    },
    equalPasswords() {
      if (this.form.password === '' || this.form.cpassword === '') {
        return null;
      }
      return this.form.cpassword === this.form.password;
    },
  },
  async created() {
    this.awaitResponse = true;
    await get(`company/${this.$store.getters.state.user.company_id}`).then(
      (response) => {
        this.name = response.data.company.name;
      },
    );
    await get(`facilitator/${this.$store.getters.state.user.id}`)
      .then((response) => {
        if (parseInt(response.data.state, 10) === 0) {
          this.isFacilitator = false;
          this.asking = false;
          this.feedback = response.data.feedback;
        } else if (parseInt(response.data.state, 10) === 1) {
          this.isFacilitator = false;
          this.asking = true;
          this.feedback = response.data.feedback;
        } else if (parseInt(response.data.state, 10) === 2) {
          this.isFacilitator = true;
          this.asking = false;
          this.feedback = response.data.feedback;
        } else if (parseInt(response.data.state, 10) === 3) {
          this.isFacilitator = false;
          this.asking = false;
          this.rejected = true;
          this.feedback = response.data.feedback;
        }
        this.awaitResponse = false;
      })
      .catch((error) => {
        this.awaitResponse = false;
      });
    this.loading = false;
  },
  methods: {
    async resetPassword(evt) {
      evt.preventDefault();
      if (this.equalPasswords && this.passwordValid) {
        const fields = JSON.parse(JSON.stringify(this.form));
        this.error = false;
        this.success = false;
        this.passwords = false;
        this.passwordLength = false;
        await post('change-password', {
          password: fields.password,
          email: fields.email,
        })
          .then((response) => {
            this.success = true;
            this.$bvModal.hide('changePassword');
          })
          .catch((error) => {
            if (
              error.data?.errors?.password[0]
              == 'The password must be at least 8 characters.'
            ) {
              this.passwordLength = true;
            } else if (error.data?.errors?.password[0]) {
              this.passwords = true;
            } else {
              this.error = true;
            }
          });
      }
    },
    async askFacilitatorProfile() {
      this.error = false;
      this.success = false;
      this.loading = true;
      post('/facilitator', {
        user: this.$store.getters.state.user.id,
      })
        .then((response) => {
          this.success = true;
          this.asking = true;
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          this.loading = false;
        });
    },
  },
};
</script>
